import { Providers } from './providers'
import { Router } from './router'
import ReactPixel from 'react-facebook-pixel'
import './scss/main.scss'
import { MixPanel } from './services/mixpanelActions'

declare global {
    interface Window {
        gtag_report_conversion: () => void
        gtag_report_form_submit: () => void
        gtag: (...args: string[]) => void
        fbq: (track: string, event: string) => void
        hbspt: {
            forms: {
                // eslint-disable-next-line no-empty-pattern
                create: ({
                    portalId,
                    formId,
                    target,
                    onFormSubmit,
                    onFormReady,
                }: {
                    portalId: string | undefined
                    formId: string | undefined
                    target: string
                    onFormSubmit: (() => void) | undefined
                    onFormReady: ((form: HTMLFormElement) => void) | undefined
                }) => void
            }
        }
    }
}
ReactPixel.init(`${process.env.REACT_APP_FB_PIXEL_ID}`, undefined, {
    autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
})
ReactPixel.pageView()

MixPanel.actions.init()

function App() {
    return (
        <Providers>
            <Router />
        </Providers>
    )
}

export default App
